import React from 'react'
import { Heading, Flex } from '@chakra-ui/react'
import HookForm from '../components/form1'
import Header from '../components/header'

export default function Home() {
        const Project = 'GP1002'

        return (
                <>
                        <Header />
                        <Heading as="h1" fontSize="3xl" m="4" align="center">
                                {Project} Self Tape Submissions
                        </Heading>
                        <Flex as="main" width="full" align="center" flexDirection="column" justifyContent="center">
                                <HookForm project={Project} />
                        </Flex>
                </>
        )
}
